import React, { useState, useEffect, useRef } from 'react';
import './Home.css';

const images = [
  'assets/datacenter.webp',
  'assets/gta-background.webp',
  'assets/minecraft-background.webp',
  'assets/ark-background.webp',
  'assets/rust-background.webp',
  'assets/arma3-background.webp',
  'assets/assetto_corsa-background.webp',
  'assets/dayz-background.webp',
  'assets/palworld-background.webp',
  'assets/redm-background.webp'
];

const preloadImages = (imageArray) => {
  imageArray.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};

const HomePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    preloadImages(images);

    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 6000);
    };

    resetTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [currentImageIndex]);

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
  };

  const handleButtonClick = (index) => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentImageIndex(index);
    }
  };

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const Card = ({ title, subtitle, logo3, features }) => {
    return (
      <div className="card">
        <div className="card-header">
          <div className="title-logo">
            <div>
              <h3 className="title">{title}</h3>
              {subtitle && <p className="subtitle">{subtitle}</p>}
            </div>
            <img src={logo3} alt="Logo" className="logo3" />
          </div>
        </div>
        <div className="card-content">
          <ul className="card-list">
            {features.map((feature, index) => (
              <li key={index} className="feature-item">
                <img src={feature.icon} alt="Feature Icon" className="feature-icon" />
                {feature.text}
              </li>
            ))}
          </ul>
        </div>
        <div className="card-footer">
          <button className="card-button">View all</button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="homepage-container" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
        <img src="assets/logo.webp" alt="Logo" className="logo2" />
        <div className="arrow left-arrow" onClick={handlePrev}>&#10094;</div>
        <div className="arrow right-arrow" onClick={handleNext}>&#10095;</div>
        <div className="content">
          <h1 className="welcome-animation">Welcome to SwiftyNode</h1>
          <h3>SwiftyNode: Elevating Hosting Excellence! Order Now for the Best in Game and Dedicated Servers!</h3>
          <button className="order-now-button">View all products</button>
        </div>
        <div className="background-buttons">
          {images.map((_, index) => (
            <button
              key={index}
              className={`background-button ${currentImageIndex === index ? 'active' : ''}`}
              onClick={() => {
                if (!isTransitioning) {
                  handleButtonClick(index);
                }
              }}
              disabled={isTransitioning}
              aria-label={`Set background image to ${index}`}
            ></button>
          ))}
        </div>
      </div>
      <div className="gap"></div>
      <div className="cards-container">
        <Card 
          title="FiveM Hosting" 
          subtitle="Starting from €4 per month" 
          logo3="assets/fivem.gif" 
          features={[
            { text: 'AMD Ryzen 7950X', icon: 'assets/amd.webp' },
            { text: 'No slot limit', icon: 'assets/slot.webp' },
            { text: 'NVMe SSD Storage', icon: 'assets/ssd.webp' },
            { text: 'Game DDoS protection', icon: 'assets/ddos.webp' },
            { text: '99% Up Time', icon: 'assets/uptime.webp' },
            { text: 'Extended panel', icon: 'assets/extended_panel.webp' }
          ]} 
        />
        <Card 
          title="Rust Hosting" 
          subtitle="Starting from €16 per month" 
          logo3="assets/rust.webp" 
          features={[
            { text: 'AMD Ryzen 7950X', icon: 'assets/amd.webp' },
            { text: 'No slot limit', icon: 'assets/slot.webp' },
            { text: 'NVMe SSD Storage', icon: 'assets/ssd.webp' },
            { text: 'Game DDoS protection', icon: 'assets/ddos.webp' },
            { text: '99% Up Time', icon: 'assets/uptime.webp' },
            { text: 'Extended panel', icon: 'assets/extended_panel.webp' }
          ]} 
        />
        <Card 
          title="Minecraft Hosting" 
          subtitle="Starting from €2 per month" 
          logo3="assets/minecraft.gif" 
          features={[
            { text: 'AMD Ryzen 7950X', icon: 'assets/amd.webp' },
            { text: 'No slot limit', icon: 'assets/slot.webp' },
            { text: 'NVMe SSD Storage', icon: 'assets/ssd.webp' },
            { text: 'Game DDoS protection', icon: 'assets/ddos.webp' },
            { text: '99% Up Time', icon: 'assets/uptime.webp' },
            { text: 'Extended panel', icon: 'assets/extended_panel.webp' }
          ]} 
        />
      </div>
      <div className="new-container">
        <div className="big-card-container">
          <div className="text-content welcome-animation">
            <h2 className="container-header">Our Game Panel</h2>
            <p className="highlight-text">Our Game panel is top-notch quality, we offer features that other hosting's would not offer for free.</p>
            <p className="highlight-text">We are way further ahead of our competitors.</p>
            <div className="icon-list-wrapper">
              <ul className="icon-list">
                <li><img src="assets/console.webp" alt="Console Icon" /> Console</li>
                <li><img src="assets/file_manager.webp" alt="File Manager Icon" /> File Manager</li>
                <li><img src="assets/database_manager.webp" alt="Database Manager Icon" /> Database Manager</li>
                <li><img src="assets/settings.webp" alt="Server Startup Settings Icon" /> Server Startup Settings</li>
              </ul>
              <ul className="icon-list">
                <li><img src="assets/users_manager.webp" alt="Users Managers Icon" /> Users Managers</li>
                <li><img src="assets/backups_manager.webp" alt="Backups Manage Icon" /> Backups Manage</li>
                <li><img src="assets/network_manager.webp" alt="Network Manager Icon" /> Network Manager</li>
                <li><img src="assets/activity_logs.webp" alt="Activity Logs Icon" /> Activity Logs</li>
              </ul>
            </div>
          </div>
          <div className="image-content">
            <img src="assets/panel.webp" alt="Game Panel" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;