import React from 'react';
import './ErrorPages.css'; // Import CSS file for styling

const Forbidden = ({ errorCode, imagePath, errorMessage }) => {
  return (
    <div className="error-container">
      <img src={imagePath} alt="Error" className="error-image" />
      <h1>{errorCode}</h1>
      <p>{errorMessage}</p>
    </div>
  );
};

const Error403 = () => {
  return <Forbidden errorCode="403 Forbidden" imagePath="/assets/lightning.webp" errorMessage="Sorry, you are not authorized to access this page." />;
};

export default Error403;