import React from 'react';
import './Footer.css';
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaEnvelope,
  FaInfoCircle,
  FaShieldAlt,
  FaFileContract
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {

  const openCrispChat = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <img src="assets/logo.webp" alt="Swiftynode Logo" className="footer-logo" loading="lazy" />
        <div className="footer-content">
          <ul className="footer-links">
            <li>
              <Link to="/about">
                <FaInfoCircle aria-label="About Us" /> About Us
              </Link>
            </li>
            <li>
              <Link to="/privacy">
                <FaShieldAlt aria-label="Privacy Policy" /> Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms">
                <FaFileContract aria-label="Terms of Service" /> Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/" onClick={openCrispChat}>
                <FaEnvelope aria-label="Contact" /> Contact
              </Link>
            </li>
          </ul>
          <div className="footer-social-media">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebook alt="" /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FaTwitter alt="" /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FaLinkedin alt="" /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram alt="" /></a>
          </div>
        </div>
        <div className="footer-slogans">
          <p>"Connecting the Future, One Node at a Time."</p>
          <p>"Your Trusted Partner in Blockchain Solutions."</p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2024 SWIFTYNODE B.V. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
