import React from 'react';
import './ErrorPages.css'; // Import CSS file for styling

const ServerError = ({ errorCode, imagePath, errorMessage }) => {
  return (
    <div className="error-container">
      <img src={imagePath} alt="Error" className="error-image" />
      <h1>{errorCode}</h1>
      <p>{errorMessage}</p>
    </div>
  );
};

const Error400 = () => {
  return <ServerError errorCode="500 Server Error" imagePath="/assets/lightning.webp" errorMessage="Oops! Something may have happened on our end. Please try again later." />;
};

export default Error400;