import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { faCaretUp, faCaretDown, faBook, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Header.css';

const Header = () => {
  const [dropdownState, setDropdownState] = useState({
    gameHosting: false,
    dedicatedServers: false,
    vpsServers: false,
  });

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;
    document.head.appendChild(script);

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'd8be5a35-1cc6-4c68-8a79-46e3b8d0d9f7';

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleScroll = () => {
    const header = document.querySelector('.header');
    if (window.scrollY > 0) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    setDropdownState(prevState => ({
      ...prevState,
      [dropdown]: !prevState[dropdown]
    }));
  };

  const handleMouseEnter = (dropdown) => {
    if (window.innerWidth > 768) {
      setDropdownState(prevState => ({
        ...prevState,
        [dropdown]: true
      }));
    }
  };

  const handleMouseLeave = (dropdown) => {
    if (window.innerWidth > 768) {
      setDropdownState(prevState => ({
        ...prevState,
        [dropdown]: false
      }));
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openCrispChat = (e) => {
    e.preventDefault();
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    } else {
      setTimeout(() => {
        window.$crisp.push(["do", "chat:open"]);
      }, 500);
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="/" onClick={(e) => { e.preventDefault(); window.location.href = '/'; }}>
          <img src="assets/logo.webp" alt="Swiftynode | Truly the best" />
          <img className="gif-overlay" src="assets/lightning.gif" alt="GIF" />
        </a>
      </div>
      <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li className={`dropdown ${dropdownState.gameHosting ? 'open' : ''}`} 
              onMouseEnter={() => handleMouseEnter('gameHosting')} 
              onMouseLeave={() => handleMouseLeave('gameHosting')}
              onClick={() => toggleDropdown('gameHosting')}>
            <Link to="/game-hosting" className="dropdown-button" >
              Game Servers {dropdownState.gameHosting ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
            </Link>
            <ul className={`dropdown-content ${dropdownState.gameHosting ? 'open' : ''}`}>
              <li><Link to="/#" ><img src="assets/fivem.webp" alt="FiveM Hosting" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>FiveM Hosting</span></Link></li>
              <li><Link to="/#" ><img src="assets/rust.webp" alt="Rust Hosting" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>Rust Hosting</span></Link></li>
              <li><Link to="/#" ><img src="assets/minecraft.webp" alt="Minecraft Hosting" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>Minecraft Hosting</span></Link></li>
              <li><Link to="/#" ><img src="assets/rdr2.webp" alt="Red Dead Redemption 2 Hosting" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>Red Dead Redemption 2 Hosting</span></Link></li>
              <li><Link to="/#" ><img src="assets/dayz.webp" alt="Dayz Hosting" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>Dayz Hosting</span></Link></li>
              <li><Link to="/#" ><img src="assets/arma3.webp" alt="Arma 3 Hosting" style={{ width: 'auto', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle' }}>Arma 3 Hosting</span></Link></li>
            </ul>
          </li>
          <li className={`dropdown ${dropdownState.dedicatedServers ? 'open' : ''}`} 
              onMouseEnter={() => handleMouseEnter('dedicatedServers')} 
              onMouseLeave={() => handleMouseLeave('dedicatedServers')}
              onClick={() => toggleDropdown('dedicatedServers')}>
            <Link to="/dedicated" className="dropdown-button" >
              Dedicated Servers {dropdownState.dedicatedServers ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
            </Link>
            <ul className={`dropdown-content ${dropdownState.dedicatedServers ? 'open' : ''}`}>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="2x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>2x AMD Ryzen 5950X</span></Link></li>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="4x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>4x AMD Ryzen 5950X</span></Link></li>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="6x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>6x AMD Ryzen 5950X</span></Link></li>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="8x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>8x AMD Ryzen 5950X</span></Link></li>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="10x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>10x AMD Ryzen 5950X</span></Link></li>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="12x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>12x AMD Ryzen 5950X</span></Link></li>
              <li><Link to="/#" ><img src="assets/vps.webp" alt="16x AMD Ryzen 5950X" style={{ width: '18px', height: '18px', marginRight: '5px', verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>16x AMD Ryzen 5950X</span></Link></li>
            </ul>
          </li>
          <li><a href="https://help.swiftynode.com" ><FontAwesomeIcon icon={faBook} /> Help Desk</a></li>
          <li><Link to="/" onClick={openCrispChat}><FontAwesomeIcon icon={faEnvelope} /> Contact</Link></li>
        </ul>
      </nav>
      <div className="cta">
        <Link to="/#" className="btn" style={{ backgroundColor: 'transparent', color: 'white', marginLeft: '10px' }} >Login</Link>
        <Link to="/#" className="btn" >Dashboard</Link>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        <i className={`fas ${menuOpen ? 'fa-times' : 'fa-bars'}`}></i>
      </button>
    </header>
  );
};

export default Header;
